.rv-login-mobile-form .rv-form__footer {
  @apply mt-10;
}
.rv-mobile-control .rv-field__value {
  @apply pl-5;
}
.rv-mobile-control .rv-cell__left-icon {
  @apply px-2;
}
.rv-login-mobile-form .rv-field__control {
  @apply text-lg;
}
.rv-login-code-input li[class*="rv-hairline"]::after {
  border-color: var(--rv-gray-6) !important;
}
.rv-login-code-input .rv-password-input__item {
  @apply font-bold !important;
}
.rv-login-code-input .rv-password-input__security::after {
  border-color: var(--rv-gray-6) !important;
}
