.rv-checkstand .rv-cell {
  padding-left: 0 !important;
}
.money-field .rv-input__control {
  font-size: 30px;
  font-weight: var(--rv-font-weight-bold);
}
.money-field .rv-field__label {
  width: 20px !important;
  margin-right: 0 !important;
  font-weight: var(--rv-font-weight-bold);
  position: relative;
  top: 10px;
}
.rv-checkstand .rv-number-keyboard {
  background-color: var(--rv-gray-3) !important;
}
