.rv-user-curtain {
  @apply pt-14 pb-6 bg-white;
}
.rv-ucenter-menu .rv-cell.rv-cell--center {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.dot {
  @apply block rounded-full bg-gray-100;
  width: 13px;
  height: 13px;
  position: absolute;
}
