.special-card-project-content table {
    @apply border-collapse border border-slate-400;
}

.special-card-project-content table th {
    @apply bg-gray-200;
}

.special-card-project-content table th,
.special-card-project-content table td {
    @apply border border-slate-300 text-center py-1;
}