.rv-pay-link {
  @apply rounded px-12 mt-10 !important;
}

.rv-paytype-cell {
  @apply bg-gray-100 !important;
}

.rv-credit-button-wrap {
  @apply rounded-lg fixed bottom-10 w-full px-5 !important;
}

.rv-credit-button-wrap .rv-button {
  @apply rounded-lg !important;
}

.rv-active-credit-card {
  background-color: var(--rv-primary-color) !important;
}
.rv-active-credit-card .rv-typography {
  @apply text-white !important;
}
