:root {
  --rv-warning-color: #f97316 !important;
  --rv-primary-color: #2563eb !important;
  --rv-success-color: #10b981 !important;
  --rv-button-danger-background-color: #ef4444 !important;
  --rv-action-bar-height: 70px !important;
  --rv-action-bar-button-danger-color: #ef4444 !important;
  --rv-action-bar-button-height: 50px !important;
  --rv-submit-bar-height: 70px !important;
  --rv-submit-bar-button-height: 50px !important;
}
