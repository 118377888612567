/* 车牌输入样式优化 */
#vehiclePlateKeyboard .confirm {
  @apply shadow-2xl border-t-2 border-gray-200 !important;
}
#vehiclePlateKeyboard .confirm p {
  @apply text-gray-500 font-bold !important;
}
#vehiclePlateKeyboard .confirm p:before {
  content: "x";
  @apply mr-1 text-lg font-bold text-sky-500 !important;
}
#vehiclePlateKeyboard .keyboard {
  @apply bg-gray-300 !important;
}
#vehiclePlateKeyboard .vehicle-plate-keyboard-container {
  min-height: 61.6vw !important;
  box-shadow: none !important;
}
#vehiclePlateKeyboard .keyboard-cell {
  @apply border border-gray-300 shadow !important;
}
#vehiclePlateKeyboard .keyboard-cell:active {
  @apply shadow-gray-300 !important;
}
#vehiclePlateKeyboard .keyboard-cell .cell-text {
  @apply text-black !important;
}
#vehiclePlateKeyboard .keyboard-cell.back-btn {
  @apply bg-gray-400 border border-gray-400  !important;
}
#vehiclePlateKeyboard .keyboard-cell.back-btn .back-btn-svg {
  width: 7.4vw !important;
}

.rv-parking-menu .rv-grid-item__text {
  @apply mt-1;
}
.rv-license-code-input {
  @apply mx-1 !important;
}
.rv-license-code-input .rv-password-input__security li {
  @apply border border-gray-300;
}
.rv-license-code-input .rv-password-input__security li:nth-child(8) {
  @apply border-green-400 font-bold text-green-400;
}
.rv-user-license-button {
  @apply bg-indigo-50 border-indigo-200 rounded;
}
.rv-user-license-button:active {
  @apply bg-indigo-100 border-indigo-200;
}
