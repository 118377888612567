.arc {
  content: "";
  background-color: #fff;
  width: 110%;
  height: 70px;
  position: absolute;
  left: -5%;
  top: -50px;
  z-index: 1;
  border-radius: 50% 50% 0 0;
}
